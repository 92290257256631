import moment from 'moment';
import queryString from 'query-string';
import Cookie from 'universal-cookie';

const cookies = new Cookie();

const EXPIRATION_DAYS = 20;

export function get(name: string) {
  return cookies.get(name);
}

export function set(name: string, value: string, expireDate?: Date) {
  const { hostname } = window.location;
  const domain = hostname === 'localhost' ? hostname : `.${hostname.replace('www.', '')}`;

  const expires = moment().add(1, 'years').toDate();

  cookies.set(name, value, { domain, expires: expireDate || expires });
}

export const saveParamsToCookies = () => {
  const { hostname } = window.location;
  const domain = hostname === 'localhost' ? hostname : `.${hostname.replace('www.', '')}`;
  const qs = queryString.parse(window.location.search);

  const code = qs.c || qs.code;
  if (code) {
    // TODO: Rename to `freska_coupon_code` and sync checkout
    cookies.set('coupon-code', code as string, {
      domain,
      expires: moment().add(7, 'days').toDate(),
    });
  }
};

export type FreskaCookie =
  | 'freska_apartment_size'
  | 'freska_consent_choice'
  | 'freska_features'
  | 'freska_locale'
  | 'freska_postcode'
  | 'freska_top_banner'
  | 'freska_visited'
  | 'coupon-code'
  | 'hubspotutk'
  | 'tracking-preferences';

export class Cookies {
  static get(name: FreskaCookie) {
    return cookies.get(name);
  }

  static set(name: FreskaCookie, value: string | Record<string, unknown>, expirationDays = EXPIRATION_DAYS) {
    cookies.set(name, value, {
      path: '/',
      expires: moment().add(expirationDays, 'days').toDate(),
    });
  }

  static setSiteWide(name: FreskaCookie, value: string | Record<string, unknown>, expirationDays = EXPIRATION_DAYS) {
    cookies.set(name, value, {
      path: '/',
      expires: moment().add(expirationDays, 'days').toDate(),
      domain: window.location.hostname.replace(/^[a-zA-Z0-9]*\./, '.'),
    });
  }

  static remove(name: FreskaCookie) {
    cookies.remove(name);
  }
}
