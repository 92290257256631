import type { ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { IntlProvider } from 'react-intl';

import { siteConfig } from '../lib/config';
import { Cookies } from '../lib/cookies';

type LocaleState = {
  locale: string;
  setLocale: (locale: string) => void;
};

const LocaleContext = createContext<LocaleState | null>(null);

export type LocaleProviderProps = {
  children: ReactNode;
  initialLocale: string;
};

export const LocaleProvider = ({ children, initialLocale }: LocaleProviderProps) => {
  const [locale, setLocale] = useState(initialLocale ?? siteConfig.defaultLocale);

  const messages = useMemo(
    () => ({
      en: require(`./translations/${siteConfig.defaultLocale}-en.json`),
      [siteConfig.defaultLocale]: require(`./translations/${siteConfig.defaultLocale}.json`),
    }),
    [],
  );

  useEffect(() => {
    Cookies.set('freska_locale', locale);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) throw new Error('useLocale must be within LocaleProvider');
  return context;
};
