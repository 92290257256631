import type { Plugin } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { Cookies } from './cookies';

export enum ConsentChoice {
  FULL = 'full',
  MEDIUM = 'medium',
  ESSENTIAL = 'essential',
}

type SegmentIntegration =
  | 'AdWords'
  | 'Facebook Conversions API (Actions)'
  | 'Facebook Pixel'
  | 'Google Ads (Classic)'
  | 'Google Analytics 4 Web'
  | 'Google Tag Manager'
  | 'Hotjar'
  | 'HubSpot'
  | 'LinkedIn Insight Tag'
  | 'TikTok Conversions';

// These must match what is set up in Segment
type ConsentCategory = 'advertising' | 'analyticsMarketing';

type ConsentSettings = {
  categoryPreferences: Record<ConsentCategory, boolean>;
  integrations: Record<SegmentIntegration, boolean>;
};

const consentSettingsMap: Record<ConsentChoice, ConsentSettings> = {
  [ConsentChoice.FULL]: {
    categoryPreferences: {
      advertising: true,
      analyticsMarketing: true,
    },
    integrations: {
      AdWords: true,
      'Facebook Conversions API (Actions)': true,
      'Facebook Pixel': true,
      'Google Ads (Classic)': true,
      'Google Analytics 4 Web': true,
      'Google Tag Manager': true,
      Hotjar: true,
      HubSpot: true,
      'LinkedIn Insight Tag': true,
      'TikTok Conversions': true,
    },
  },
  [ConsentChoice.MEDIUM]: {
    categoryPreferences: {
      advertising: false,
      analyticsMarketing: true,
    },
    integrations: {
      AdWords: false,
      'Facebook Conversions API (Actions)': false,
      'Facebook Pixel': false,
      'Google Ads (Classic)': false,
      'Google Analytics 4 Web': true,
      'Google Tag Manager': false,
      Hotjar: true,
      HubSpot: true,
      'LinkedIn Insight Tag': false,
      'TikTok Conversions': false,
    },
  },
  [ConsentChoice.ESSENTIAL]: {
    categoryPreferences: {
      advertising: false,
      analyticsMarketing: false,
    },
    integrations: {
      AdWords: false,
      'Facebook Conversions API (Actions)': false,
      'Facebook Pixel': false,
      'Google Ads (Classic)': false,
      'Google Analytics 4 Web': false,
      'Google Tag Manager': false,
      Hotjar: false,
      HubSpot: false,
      'LinkedIn Insight Tag': false,
      'TikTok Conversions': false,
    },
  },
};

const consent: Plugin = {
  name: 'Consent category preferences',
  type: 'before',
  version: '1.0.0',
  isLoaded: () => true,
  load: () => Promise.resolve(),
  track: (ctx) => {
    const consentChoice = Cookies.get('freska_consent_choice') as ConsentChoice;
    const categoryPreferences = consentSettingsMap[consentChoice ?? ConsentChoice.ESSENTIAL].categoryPreferences;

    ctx.event.context = {
      ...ctx.event.context,
      consent: { categoryPreferences },
    };

    return ctx;
  },
};

export const initAnalytics = () => {
  window.analytics = new AnalyticsBrowser();
  window.analytics.register(consent);
};

export const loadAnalyticsConditionally = () => {
  if (window.analyticsLoaded) {
    // Reload the page if the consent was changed afterwards so the user's new preferences can take affect
    window.location.reload();
    return;
  }

  const consentChoice = Cookies.get('freska_consent_choice') as ConsentChoice | null;
  if (!consentChoice) {
    // This will make sure that the user is prompted to make a consent choice
    window.analyticsLoaded = false;
    window.analyticsCanTrack = false;
    return;
  }

  if (consentChoice === ConsentChoice.ESSENTIAL) {
    // Flag analytics as loaded, but don't load it in reality if the user didn't consent to anything
    window.analyticsLoaded = true;
    window.analyticsCanTrack = false;
    return;
  }

  window.analytics.load(
    {
      writeKey: process.env.GATSBY_SEGMENT_WRITE_KEY,
      cdnURL: process.env.GATSBY_SEGMENT_CDN_URL,
    },
    {
      integrations: {
        ...consentSettingsMap[consentChoice].integrations,
        'Segment.io': {
          apiHost: process.env.GATSBY_SEGMENT_API_HOST,
          protocol: 'https',
        },
      },
    },
  );

  window.analyticsLoaded = true;
  window.analyticsCanTrack = true;
};
