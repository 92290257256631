import React from 'react';

import type { GatsbyBrowser } from 'gatsby';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { BusinessConfigurationProvider } from './src/hooks/business-configuration';
import { LocaleProvider } from './src/i18n/locale-provider';
import { initAnalytics, loadAnalyticsConditionally } from './src/lib/analytics';
import { Cookies, saveParamsToCookies } from './src/lib/cookies';
import {
  trackCheckoutVisitorReturned,
  trackWebsiteVisitorReturned,
  trackNewVisitorVisited,
  trackPage,
} from './src/lib/tracking';
import type { PageContext } from './src/types';
import './src/styles/global.css';

saveParamsToCookies();

window.gtag = function gtag() {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BusinessConfigurationProvider>{element}</BusinessConfigurationProvider>
    </QueryClientProvider>
  );
};

export const wrapPageElement: GatsbyBrowser<any, PageContext>['wrapPageElement'] = ({ element, props }) => {
  return <LocaleProvider initialLocale={props.pageContext.locale}>{element}</LocaleProvider>;
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  initAnalytics();
  loadAnalyticsConditionally();

  if (Cookies.get('freska_postcode')) {
    // If postcode was stored in the cookies, we can assume visitor
    // has visited checkout previously
    trackCheckoutVisitorReturned();
  } else if (Cookies.get('freska_visited')) {
    trackWebsiteVisitorReturned();
  } else {
    // If none of the above, then it's a new visitor
    trackNewVisitorVisited();
    Cookies.set('freska_visited', 'true');
  }
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  // Remove this when we get rid of Giosg
  window.FRESKA_PATHNAME = location.pathname;

  // Refresh HubSpot widget
  window.HubSpotConversations?.widget.refresh();

  trackPage();
};
