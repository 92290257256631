import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { FEATURES_COOKIE_KEY } from '../hooks/business-configuration';
import type { HubSpotFormField } from '../hooks/useHubSpotForm';

import { siteConfig } from './config';
import { Cookies } from './cookies';

let topBannerShownTracked = false;

const defaultProperties = () => {
  const activeFeatures = Cookies.get(FEATURES_COOKIE_KEY) || {};

  return {
    activeFeatures,
    activeFeaturesJson: JSON.stringify(activeFeatures),
    language: Cookies.get('freska_locale') ?? siteConfig.defaultLocale,
  };
};

export const track = async (event: string, properties?: Record<string, unknown>) => {
  await window.analytics.track(event, { ...properties, ...defaultProperties() });
};

export const trackPage = () => {
  window.analytics.page(defaultProperties());
};

export const trackCheckoutVisitorReturned = () => {
  track('Checkout Visitor Returned', { nonInteraction: 1 });
};

export const trackWebsiteVisitorReturned = () => {
  track('Website Visitor Returned', { nonInteraction: 1 });
};

export const trackNewVisitorVisited = () => {
  track('New Visitor Visited', { nonInteraction: 1 });
};

export const trackFeaturesInitialized = () => {
  track('Features Initialized', { nonInteraction: 1 });
};

export const trackVariantContainerBlockShown = (experimentId: string, variant: string | null) => {
  track('Variant Container Block Shown', { experimentId, variant, nonInteraction: 1 });
};

export const trackTopBannerShown = (label?: string) => {
  if (!topBannerShownTracked) {
    track('Top Banner Shown', { label, nonInteraction: 1 });
    topBannerShownTracked = true;
  }
};

export const trackTopBannerClicked = (label?: string) => {
  track('Top Banner Clicked', { label });
};

export const trackTopBannerClosed = (label?: string) => {
  track('Top Banner Closed', { label });
};

export const trackOrderFormSubmitted = (isModal: boolean, properties: { postcode: string; apartmentSize: string }) => {
  track(`Order Form Submitted${isModal ? ' In Modal' : ''}`, { category: 'Order Form', ...properties });
};

export const trackNoAvailabilityForPostcode = (postcode: string) => {
  track('No Availability For Postcode', { category: 'Order Form', postcode });
};

export const trackPostcodeWasNotFound = (postcode: string) => {
  track('Postcode Was Not Found', { category: 'Order Form', postcode });
};

export const trackLinkClicked = (to: string, context?: string, label?: string) => {
  track('Link Clicked', { to, context, label });
};

export const trackLeadFormSubmitted = (category: string, fields: HubSpotFormField[], isInWorkingArea?: boolean) => {
  const properties: Record<string, string> = fields.reduce(
    (props, field) => ({ ...props, [camelCase(field.name)]: field.value }),
    {},
  );

  track(`${isInWorkingArea ? '' : 'Outside Working Area '}${startCase(properties.leadSource)} Form Submitted`, {
    category,
    ...properties,
  });
};

export const shareButtonClicked = (type: string) => {
  track(`${type} Share Button Clicked`);
};

export const trackOrderingOptionsShown = () => {
  track('Ordering Options Shown', { category: 'Order Form' });
};

export const trackBookOnlineClicked = async () => {
  await track('Book Online Clicked', { category: 'Order Form' });
};

export const trackBookByPhoneClicked = async () => {
  await track('Book by Phone Clicked', { category: 'Order Form' });
};

export type TrackFormSubmittedProperties = {
  formName: string;
  formObjective: 'checkout' | 'lead' | 'newsletter';
};

export const trackFormSubmitted = async (properties: TrackFormSubmittedProperties) => {
  await track('Form Submitted', properties);
};

export type TrackFormSubmissionFailedProperties = {
  formName: string;
  failureReason: 'form_validation' | 'other';
};

export const trackFormSubmissionFailed = async (properties: TrackFormSubmissionFailedProperties) => {
  await track('Form Submission Failed', properties);
};
